import React from 'react';
import './create_appointment.scss';
import styled from 'styled-components';
import moment from 'moment';
import $ from "jquery";
import { PulseLoader } from 'react-spinners';
import exclamationIcon from '../../../images/exclamation-circle-solid.svg';
import CSSFilter from '../../styles/css-filter';
import { hexToRgb } from '../../styles/css-filter';
import prefix from 'react-prefixer';

import FlexIcon from '../../styles/flex_icon';

class CreateAppointment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loaded: false,
      hover: JSON.parse(window.sessionStorage.phoneType)
    }

    this.handleSchedule = this.handleSchedule.bind(this);
    this.handleSite = this.handleSite.bind(this);
  }

  componentDidMount() {
    const scrolly = document.getElementsByClassName("div2");
    scrolly[0].style.display = "block";

    const backButton = document.getElementsByClassName("backButton");
    $(backButton).addClass("hideButton");

    if (parseInt(window.sessionStorage.currentPage) === 0) {
      if (this.props.match.params.analytics !== undefined) {
        this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/`);
      } else {
        this.props.history.push(`/${this.props.match.params.id}/`);
      }
    }

    window.sessionStorage.setItem("currentPage", 10);
  }

  handleSchedule(e) {
    e.preventDefault();
    window.sessionStorage.removeItem("appointmentId");
    window.sessionStorage.removeItem("backSkip");
    window.sessionStorage.removeItem("config");
    window.sessionStorage.removeItem("providerId");
    window.sessionStorage.removeItem("patientType");
    window.sessionStorage.removeItem("backButton");
    window.sessionStorage.removeItem("currentPage");
    window.sessionStorage.removeItem("provider");
    window.sessionStorage.removeItem("locationId");
    window.sessionStorage.removeItem("selectedAppointment");
    window.sessionStorage.removeItem("backPage");
    window.sessionStorage.removeItem("firstAvailable");
    window.sessionStorage.removeItem("confirmedAppt");
    window.sessionStorage.removeItem("cacheDays");
    window.sessionStorage.removeItem("SchedulePreferences");
    window.sessionStorage.removeItem("patientPrefs");
    window.sessionStorage.removeItem("appointments");
    window.sessionStorage.removeItem("optionsDow");
    window.sessionStorage.removeItem("dow");
    window.sessionStorage.removeItem("phoneType");
    window.sessionStorage.removeItem("created");

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/`);
    }
  }

  handleSite(e) {
    e.preventDefault();
  }

  render() {
    const Spacer = styled.div`
      width: ${props => props.width || 0};
      height: ${props => props.height || 0};
    `;

    let provider;
    let selectedAppointment;

    if (parseInt(window.sessionStorage.providerId) !== 0) {
      let providerIndex = this.props.config.providers.findIndex(provider => parseInt(provider.provider_assoc) === parseInt(window.sessionStorage.providerId));
      provider = this.props.config.providers[providerIndex];
    }

    if (window.sessionStorage.selectedAppointment) {
      selectedAppointment = JSON.parse(window.sessionStorage.selectedAppointment);
    }

    if (typeof selectedAppointment === "object" && selectedAppointment.length > 0) {
      selectedAppointment = selectedAppointment[0];
    }

    const rgb = hexToRgb(this.props.config.color ? this.props.config.color : "#d0d0d0");
    if (rgb.length !== 3) {
      alert('Invalid format!');
      return;
    }

    const color = new CSSFilter.Color(rgb[0], rgb[1], rgb[2]);
    const solver = new CSSFilter.Solver(color);
    const result = solver.solve();

    const iconStyle = prefix({
      filter: result.filter,
      marginTop: "15px",
      marginBottom: "15px"
    });

    const Button = styled.button`
      background-color: white;
      border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
      color: #707070 !important;
      border-radius: 5px;

      &:hover {
        background-color: ${this.state.hover ? this.props.config.color : "#2699fb"} !important;
        color: white !important;
      }
    `;

    const appointment = JSON.parse(window.sessionStorage.confirmedAppt);

    const practiceName = this.props.config.practiceName;

    window.sessionStorage.setItem("confirmedAppt", JSON.stringify(appointment));

    return (
      <div className="createAppointmentContainer row justify-content-center">
        <div className="col col-11 createContainer">

          {this.state.loading ? (
            <>
              <Spacer height="10px" />
              <PulseLoader color={"#707070"}  size={10} />
            </>
          ) : (
            window.sessionStorage.created === "created" ? (
              <>
                <div className="createAppointmentHeader">
                  Your appointment was scheduled!
                  <div className="createAppointmentHead">
                    {appointment.name}
                  </div>

                  <div className="createAppointmentHeadLength">
                    {appointment.length} minutes
                  </div>

                  <FlexIcon class="scheduleApptImage" icon={appointment.icon} />

                  <div className="createAppointmentTimes">
                    {moment(selectedAppointment.date).format("dddd, MMMM D")} at {moment(selectedAppointment.starttime, "hh:mm:ss").format("h:mm a").replace(/(a|p)(m)/,'$1.$2.')}
                  </div>
                  <div className="appointmentProvider">
                    {provider ? "with " + provider.name : "at " + practiceName}
                  </div>
                </div>


                <Button type="button" className="col col-sm-10 col-md-7 btn btn-outline-primary scheduleNewAppt" onClick={this.handleSchedule}>
                  Schedule Another
                </Button>
              </>
            ) : (
              <>
                <div className="createErrorHeader">
                  We Apologize
                </div>

                <img style={iconStyle} className="createAppointmentImage" height="60" width="60" src={exclamationIcon} alt="calendarIcon"/>

                <div className="createErrorDescription">
                  There was an issue creating this appointment. Please call {this.props.config.practicePhone} or try making a new appointment.
                </div>

                <Button type="button" className="col col-sm-12 col-md-6 btn btn-outline-primary scheduleNewAppt" onClick={this.handleSchedule}>
                  Create New Appointment
                </Button>
              </>
            )
          )}

      </div>
      </div>
    )
  }
}

export default CreateAppointment;
