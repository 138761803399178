import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SideBar from './sidebar/sidebar_container';
import Footer from './footer/footer_container';

// Page Zero
import LocationContainer from './pages/locations/locations_container';
// Page One
import PatientTypeContainer from './pages/patient_type/patient_type_container';
// Page Two
import AppointmentsContainer from './pages/appointments/appointments_container';
// Page Three
import ProvidersContainer from './pages/providers/providers_container';
// Page Four
import FirstAppointmentContainer from './pages/appointments/first_appointment_container';
// Page Five
import SchedulePreferencesContainer from './pages/schedule_preferences/schedule_preferences_container';
// Page Six
import AppointmentOptionsOneContainer from './pages/appointment_options/appointment_options_one_container';
// Page Seven
import AppointmentOptionsTwoContainer from './pages/appointment_options/appointment_options_two_container';
// Page Eight
import CalendarContainer from './pages/calendar/calendar_container';
// Page Nine
import ScheduleAppointmentContainer from './pages/schedule_appointment/schedule_appointment_container';
// Page Ten
import CreateAppointmentContainer from './pages/create_appointment/create_appointment_container';

const App = () => (
  <div className="parent">
    <SideBar />

    <div id="scrollTop" className="div2">
        <Switch>
          <Route exact path="/:id"   component={LocationContainer} />
          <Route exact path="/:id/1" component={PatientTypeContainer} />
          <Route exact path="/:id/2" component={AppointmentsContainer} />
          <Route exact path="/:id/3" component={ProvidersContainer} />
          <Route exact path="/:id/4" component={FirstAppointmentContainer} />
          <Route exact path="/:id/5" component={SchedulePreferencesContainer} />
          <Route exact path="/:id/6" component={AppointmentOptionsOneContainer} />
          <Route exact path="/:id/7" component={AppointmentOptionsTwoContainer} />
          <Route exact path="/:id/8" component={CalendarContainer} />
          <Route exact path="/:id/9" component={ScheduleAppointmentContainer} />
          <Route exact path="/:id/10" component={CreateAppointmentContainer} />
          <Route exact path="/:id/:analytics?/"   component={LocationContainer} />
          <Route exact path="/:id/:analytics?/1" component={PatientTypeContainer} />
          <Route exact path="/:id/:analytics?/2" component={AppointmentsContainer} />
          <Route exact path="/:id/:analytics?/3" component={ProvidersContainer} />
          <Route exact path="/:id/:analytics?/4" component={FirstAppointmentContainer} />
          <Route exact path="/:id/:analytics?/5" component={SchedulePreferencesContainer} />
          <Route exact path="/:id/:analytics?/6" component={AppointmentOptionsOneContainer} />
          <Route exact path="/:id/:analytics?/7" component={AppointmentOptionsTwoContainer} />
          <Route exact path="/:id/:analytics?/8" component={CalendarContainer} />
          <Route exact path="/:id/:analytics?/9" component={ScheduleAppointmentContainer} />
          <Route exact path="/:id/:analytics?/10" component={CreateAppointmentContainer} />
        </Switch>

        <Footer />
    </div>
  </div>
);

export default App;
