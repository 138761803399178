import React from 'react';
import styled from 'styled-components';
import "./patient_type.scss";
import $ from "jquery";
import { getContrast } from '../../styles/contrast';
import { PulseLoader } from 'react-spinners';

import CSSFilter from '../../styles/css-filter';
import { hexToRgb } from '../../styles/css-filter';
import prefix from 'react-prefixer';

import FlexIcon from '../../styles/flex_icon';

class PatientType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      hover: JSON.parse(window.sessionStorage.phoneType || true)
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const scrolly = document.getElementsByClassName("div2");
    scrolly[0].style.display = "flex";

    const currentPage = window.sessionStorage.currentPage;

    const div2 = document.getElementsByClassName("div2");
    $(div2).attr('style',  'overflow: scroll !important');

    if (this.props.config.mulitiste === 0 || this.props.config.mulitiste === null) {
      window.sessionStorage.removeItem("config");
      window.sessionStorage.removeItem("providerId");
      window.sessionStorage.removeItem("patientType");
      window.sessionStorage.removeItem("backButton");
      window.sessionStorage.removeItem("currentPage");
      window.sessionStorage.removeItem("provider");
      window.sessionStorage.removeItem("backPage");
      window.sessionStorage.removeItem("firstAvailable");
      window.sessionStorage.removeItem("cacheDays");
      window.sessionStorage.removeItem("SchedulePreferences");
      window.sessionStorage.removeItem("patientPrefs");
      window.sessionStorage.removeItem("appointments");
      window.sessionStorage.removeItem("optionsDow");
      window.sessionStorage.removeItem("dow");
      window.sessionStorage.removeItem("phoneType");

      window.sessionStorage.setItem("currentPage", 0);
      window.sessionStorage.setItem("backPage", 0);
    }

    if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
      window.sessionStorage.setItem("phoneType", true);
      this.setState({hover: false});
    } else {
      window.sessionStorage.setItem("phoneType", false);
    }

    if (window.location !== window.parent.location) {
      if (parseInt(currentPage) === 0) {
        window.sessionStorage.setItem("currentPage", 1);
      } else {
        if (this.props.match.params.analytics !== undefined) {
          this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        } else {
          this.props.history.push(`/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        }
      }
    } else {
      if (parseInt(currentPage) === 0 || parseInt(currentPage) === 1) {
        if (window.securedNav === true && (parseInt(currentPage) === 1 || parseInt(currentPage) === 0)) {
          window.sessionStorage.setItem("currentPage", 0);
        } else {
          window.sessionStorage.setItem("currentPage", 1);
        }
      } else {
        if (window.securedNav === true && parseInt(currentPage) === 2) {
          window.sessionStorage.setItem("currentPage", 1);
        } else {
          if (this.props.match.params.analytics !== undefined) {
            if (parseInt(currentPage) === 0) {
              this.props.history.replace(`/${this.props.match.params.id}/${this.props.match.params.analytics}/`);
            } else {
              if (currentPage === undefined) {
                this.props.history.replace(`/${this.props.match.params.id}/${this.props.match.params.analytics}/1`);
              } else {
                this.props.history.replace(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${currentPage}`);
              }
            }
          } else {
            if (parseInt(currentPage) === 0) {
              this.props.history.replace(`/${this.props.match.params.id}/`);
            } else {
              if (currentPage === undefined) {
                window.sessionStorage.setItem("currentPage", 0);
              } else {
                this.props.history.replace(`/${this.props.match.params.id}/${currentPage}`);
              }
            }
          }
        }
      }

      window.securedNav = false;
    }

    if (window.sessionStorage.locationId) {
      this.props.getConfig(window.sessionStorage.locationId)
      .then(() => {

        if (this.props.config.color) {
          const rgb = hexToRgb(this.props.config.color);

          const color = new CSSFilter.Color(rgb[0], rgb[1], rgb[2]);
          const solver = new CSSFilter.Solver(color);
          const result = solver.solve();

          if (result.loss > 30) {
            window.location.reload();
          } else {
            window.sessionStorage.setItem("iconColor", JSON.stringify(result.filter));

            this.setState({
              loaded: true
            });
          }
        } else {
          this.setState({
            loaded: true
          });
        }
      })
    } else {
      this.props.getConfig(this.props.match.params.id)
      .then(() => {

        if (this.props.config.color) {
          const rgb = hexToRgb(this.props.config.color);

          const color = new CSSFilter.Color(rgb[0], rgb[1], rgb[2]);
          const solver = new CSSFilter.Solver(color);
          const result = solver.solve();

          if (result.loss > 30) {
            window.location.reload();
          } else {
            window.sessionStorage.setItem("iconColor", JSON.stringify(result.filter));

            this.setState({
              loaded: true
            });
          }
        } else {
          this.setState({
            loaded: true
          });
        }
      })
    }

    if (!window.sessionStorage.locationId) {
      const backButton = document.getElementsByClassName("backButton");
      $(backButton).addClass("hideButton");
    } else {
      const backButton = document.getElementsByClassName("backButton");
      $(backButton).removeClass("hideButton");
    }
  }

  handleClick(e) {
    e.preventDefault();

    window.sessionStorage.setItem("patientType", e.currentTarget.dataset.patient_type);

    window.sessionStorage.setItem("currentPage", 2);

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/2`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/2`);
    }
  }

  render() {
    let contrastColor = this.props.config.color ? this.props.config.color : "#707070";

    const rgb = hexToRgb(this.props.config.color ? this.props.config.color : "#d0d0d0");
    if (rgb.length !== 3) {
      alert('Invalid format!');
      return;
    }

    const color = new CSSFilter.Color(rgb[0], rgb[1], rgb[2]);
    const solver = new CSSFilter.Solver(color);
    const result = solver.solve();

    const iconStyle = prefix({
      filter: result.filter
    });

    const Spacer = styled.div`
      width: ${props => props.width || 0};
      height: ${props => props.height || 0};
    `;

    if (!this.state.loaded) {
      return  <div className="optionsTwoLoader">
                <Spacer height="10px" />
                <PulseLoader color={"#707070"}  size={10} />
              </div>;
    }

    let hasConfig = true;

    const config = this.props.config;
    if ((Object.keys(this.props.config).length === 1 || Object.keys(this.props.config).length === 0) && this.props.constructor === Object) {
      hasConfig = false;
    }

    if (this.props.config === 500 || this.props.config === 400 || this.props.config === 404 || !hasConfig) {
      return <div className="patientContainer">
        <div className="patientChildContainer">

          <div className="row justify-content-center">
            <div className="col col-11 locationContainer">
            <div className="locationNumber">
              <i style={iconStyle} className="fas fa-exclamation-circle fa-2x"></i>
            </div>

              <div style={{marginBottom: "10px"}} className="locationAddressErr">
                This office isn't currently accepting online appointments. Give them a call instead!
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    const Button = styled.button`
      background-color: white !important;
      color: #707070 !important;
      border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;

      &:hover {
        background-color: ${this.state.hover ? this.props.config.color : "white"} !important;
        color: ${this.state.hover ? getContrast(contrastColor) : "#707070" } !important;
      }
    `;

    return (
        <div className="patientContainer">
          <div className="patientChildContainer">
            <h2 className="patientHeader">Welcome!!!</h2>

            <div className="row justify-content-center">
              <div className="col col-md-8 col-sm-12 locationDescriptionTwo">
                To book an appointment, first let us know if you've visited before.
              </div>

              <div className="col col-11 patientTypeContainer">
                <Button type="button" className="btn btn-outline-primary newPatientButton" data-patient_type={"new"} onClick={this.handleClick}>
                  New Patient
                </Button>

                <Button type="button" className="btn btn-outline-primary returningPatientButton" data-patient_type={"returning"} onClick={this.handleClick}>
                  Returning Patient
                </Button>

              </div>

              <div className="col col-11 locationContainer">
                <FlexIcon width={"35"} height={"35"} class="mapMarkerIcon" icon={10} />

                <div className="locationTypeName">
                  {!!config.practiceName ? config.practiceName : ""}
                </div>

                <div className="locationAddress">
                  {!!config.address ? config.address : ""}
                </div>

                <div className="locationNumber">
                  <a href={`tel: ${config.practicePhone}`}>
                    {config.practicePhone}
                  </a>
                </div>
              </div>
            </div>


          </div>
        </div>
    )
  }
}

export default PatientType;
