import React from 'react';
import './schedule_appointment_modal.scss';
import styled from 'styled-components';
import PropTypes from "prop-types";
import $ from "jquery";
import { PulseLoader } from 'react-spinners';
import { getContrast } from '../../styles/contrast';

class ScheduleModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      loading: false,
      errMsg: false,
      hover: JSON.parse(window.sessionStorage.phoneType)
    };

    // binders for class functions
    this.handleClick = this.handleClick.bind(this);
    this.handleNumber = this.handleNumber.bind(this);
    this.handleRetry = this.handleRetry.bind(this);
  }

  createCookieInHour = (cookieName, cookieValue, hourToExpire) => {
    let date = new Date();
    date.setTime(date.getTime()+(hourToExpire*60*60*1000));
    document.cookie = cookieName + " = " + cookieValue + "; expires = " +date.toGMTString();
  }

  // sends sms verification message
  handleClick(e) {
    e.preventDefault();
    this.setState({loading: true});

    let number = this.props.phone.replace(/[^\d]/g, '');

    let data = {
      phone: number,
      code: this.state.code
    }

    this.props.checkVerification(data)
    .then(() => {
      if (this.props.verify === "pending") {
        this.setState({
          loading: false,
          errMsg: true
        });
      }
    })
  }

  // handles retry send sms verification message
  handleRetry(e) {
    e.preventDefault();

    this.props.sendVerification(this.props.phone);
  }

  // handles closing the sms verification modal
  handleNumber(e) {
    e.preventDefault();
    this.setState({
      code: ""
    });

    this.props.onClose && this.props.onClose(e);
  }

  componentDidMount() {
  }

  update(field) {
    return e => {
        this.setState({ [field]: e.target.value})
    };
  };

  render() {
    const contrastColor = this.props.config.color ? this.props.config.color : "#2699fb";

    const Spacer = styled.div`
      width: ${props => props.width || 0};
      height: ${props => props.height || 0};
    `;

    if(!this.props.show){
      return null;
    }

    const Button = styled.button`
      background-color: white !important;
      color: #707070 !important;
      border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;

      &:hover {
        background-color: ${this.state.hover ? this.props.config.color : "white"} !important;
        color: ${this.state.hover ? getContrast(contrastColor) : "#707070" } !important;
        border-color: ${this.state.hover ? this.props.config.color : "#2699fb"} !important;
      }
    `;

    const ContinueButton = styled.button`
      border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
      background-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
      color: ${getContrast(contrastColor)} !important;

      &:hover {
        border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
        background-color: ${this.state.hover ? this.props.config.color : "white"} !important;
        color: ${this.state.hover ? getContrast(contrastColor) : "#707070"} !important;
      }
    `;

    const { code } = this.state;
    const isEnabled = code.length > 0;

    const background = document.getElementsByClassName("blurry");
    $(background).attr('style',  'filter: blur(5px);');

    let errMsg;

    if (this.state.errMsg) {
      errMsg = <div className="apptErrMsg"> The 6-digit code was incorrect. Please try entering it again</div>;
    }

    return (
      <div id="modal" ref="modal" className="modalContainer">
        <div className="row justify-content-center">

        {this.state.loading ? (
          <div className="col col-12 loaderAppt">
            <Spacer height="10px" />
            <PulseLoader color={"#707070"}  size={10} />
          </div>
        ) : (
          <>
            <div className="col col-11 verificationDescription">{`A 6-digit code was sent to`}<div className="createApptNumber">{this.props.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1‑$2‑$3")}</div></div>
            <div className="codeInput field col-10">
              <input id="code" onChange={this.update("code")} type="code" className="form-control center" placeholder="Enter Code"/>
              {errMsg}
            </div>
            <ContinueButton id="continueButton" disabled={!isEnabled} className="col col-10 verifyButtons btn btn-primary" onClick={this.handleClick}>Confirm</ContinueButton>
            <Button className="col col-10 verifyButtons btn btn-primary" onClick={this.handleNumber}>Edit Phone Number</Button>
            <button className="col col-10 didntReceiveCode btn btn-primary" onClick={this.handleRetry}>Didn't receive code? Try again</button>
          </>
        )}
        </div>
      </div>
    )
  }
}

ScheduleModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default ScheduleModal;
