import React from 'react';
import moment from 'moment';
import { PulseLoader } from 'react-spinners';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';
import './calendar.scss';
import { getContrast } from '../../styles/contrast';
import { debounce } from 'throttle-debounce';

import { ReactComponent as ArrowLeft } from '../../../images/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../images/arrow-right.svg';

const Wrapper = styled.div`
  top: 24vh;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 10%;
  @media only screen and (max-width: 850px) {
    /* top: 10%; */
  }

  @media only screen and (max-width: 480px) {
    top: 3%;
  }

  .calendar-icon {
    width: 35px;
    height: 35px;
    margin-top: 10px;

    circle {
      fill: transparent;
    }
    path {
      fill: #F4F9FF;
    }
  }

  /* Missing provider image placeholder */
  .placeholder-image circle {
    fill: #F4F9FF;
  }

  h2.title {
    color: #707070;
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    margin: 0 0 5px;
  }
  h3.subtitle {
    color: #585858;
    font-size: 18px;
    text-align: center;
    font-weight: normal;
    margin: 0 0 15px;
  }

  .calendar {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 310px;
    max-width: 310px;
    border: none;
    margin-left: 10px;

    button:not(:disabled) {
      cursor: pointer;
    }

    .react-calendar__month-view__weekdays__weekday abbr {
      cursor: default !important;
      user-select: none;
    }

    .react-calendar__month-view__days { user-select:none; }

    .react-calendar__month-view__days, /* Container around all days */
    .react-calendar__month-view__weekdays { /* Container around weekday labels */
      display: grid !important;
      grid-template-columns: repeat(7, 1fr);
      justify-items: center;
      grid-gap: 10px;

      /* The direct children of those ^ */
      .react-calendar__month-view__weekdays__weekday,
      .react-calendar__tile {
        max-width: none !important;
      }
    }

    /* Left/right buttons */
    .react-calendar__navigation__arrow {
      background-color: transparent;
      border: none;
      padding: 0;

      &:disabled path:last-of-type {
        fill: #C2E0FA;
      }
    }

    /* Month label */
    .react-calendar__navigation__label {
      color: #707070;
      background-color: transparent;
      border: none;
      font-size: 24px;
      padding: 10px 10px 15px;
      line-height: 1.25;
      white-space: nowrap;
      opacity: 100% !important;
    }

    /* Weekday labels */
    .react-calendar__month-view__weekdays__weekday > abbr {
      color: #707070;
      font-size: 16px;
      text-decoration: none;
    }

    /* Container around all day buttons */
    .react-calendar__month-view__days {
      margin-top: 10px;
    }

    /* Day button */
    button.tile {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      padding: 0;
      width: 100%;
      color: #707070;
      font-size: 16px;
      background-color: #F4F9FF;
      position: relative;
      opacity: 100%;
      border-radius: 5px;

      &.available:not(:disabled) {
        background-color: rgba(194,224,250,0.58);
      }

      &.react-calendar__tile--active {
        color: white;
        background-color: rgba(0,123,255,0.749) !important;
      }

      /* For responsive squares */
      /* see https://spin.atomicobject.com/2015/07/14/css-responsive-square/ */
      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }

      & > abbr {
        position: absolute;
        width: 100%;
        height: 100%;
        padding-top: 27%;
      }
    }

    /* Hide text on tiles from adjacent months */
    .react-calendar__month-view__days__day--neighboringMonth {
      color: transparent !important;
      background-color: #F4F9FF !important;
    }
  }
`;

const AvailableTimes = styled.div`
  margin-top: 15px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  max-width: 310px;
  max-height: 310px;
  min-width: 175px;
  overflow: hidden;
  overflow-y: scroll;

  @media (min-width: 768px) {
    flex-flow: ${props => (props.dateSelected ? 'column nowrap' : 'row wrap')};
    justify-content: ${props => (props.dateSelected ? '' : 'center')};
    margin-top: 15px;
  }

  h2.select-a-date {
    color: #707070;
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    margin: 10px 0 0;
  }

  h2.title {
    margin-bottom: 20px;
  }

  button {
    margin: 0 0 32px;

    &:last-of-type {
      margin: 0;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;

  @media (min-width: 925px) {
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: center;
  }
`;

const today = moment();

class CompareCalendars extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDate: null,
      availableTimes: [],
      loadingTimes: false,
      loaded: false,
      appointments: [],
      hover: JSON.parse(window.sessionStorage.phoneType)
    }

    this.handleAppointment = this.handleAppointment.bind(this);
    this.getAllAppointments = debounce(1500, this.getAllAppointments);
  }

  componentDidMount() {
    const scrolly = document.getElementsByClassName("div2");
    scrolly[0].style.display = null;

    if (window.sessionStorage.backSkip && parseInt(window.sessionStorage.backSkip) === 5) {
      window.sessionStorage.setItem("currentPage", 5);
      window.sessionStorage.removeItem("backSkip");
    }

    const currentPage = window.sessionStorage.currentPage;

    if (window.location !== window.parent.location) {
      if (parseInt(currentPage) === 7) {
        window.sessionStorage.setItem("currentPage", 8);
        window.sessionStorage.setItem("backPage", 7);
      } else {
        if (this.props.match.params.analytics !== undefined) {
          this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        } else {
          this.props.history.push(`/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        }
      }
    } else {
      if (parseInt(currentPage) === 7 || parseInt(currentPage) === 8 || parseInt(currentPage) === 5) {
        if (parseInt(currentPage) !== 5) {
            window.sessionStorage.setItem("currentPage", 8);
        }
      } else {
        if (window.securedNav === true && parseInt(currentPage) === 9) {
          window.sessionStorage.setItem("currentPage", 8);
          window.securedNav = false;

        } else {
          if (this.props.match.params.analytics !== undefined) {
            this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
          } else {
            this.props.history.push(`/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
          }
        }
      }
    }

    let month;
    let year;
    let data;

    if (window.sessionStorage.firstAvailable) {
      let m = moment(window.sessionStorage.firstAvailable);
      let y = moment(window.sessionStorage.firstAvailable);
      month = parseInt(m.format("MM"));
      year = parseInt(y.format("YYYY"));
    } else {
      month = parseInt(moment().format("MM"));
      year = parseInt(moment().format("YYYY"));
    };

    let providerId = window.sessionStorage.providerId;

    let providerIdx = this.props.config.providers.findIndex(providers => parseInt(providerId) === parseInt(providers.provider_assoc))
    let foundProvider = this.props.config.providers[providerIdx];


    if (foundProvider) {
       providerId = foundProvider.provider_id;
    }

    if (window.sessionStorage.locationId) {
      data = {
        client: window.sessionStorage.locationId,
        appointmentId: window.sessionStorage.appointmentId,
        providerId: providerId,
        month: month,
        year: year
      }
    } else {
      data = {
        client: this.props.match.params.id,
        appointmentId: window.sessionStorage.appointmentId,
        providerId: providerId,
        month: month,
        year: year
      }
    }

    this.getAllAppointments(data)

  }

  scrollToBottomTop() {
    this.el.scrollIntoView({ behavior: 'smooth', block: "start" });
  };

  handleAppointment = data => e => {
    e.preventDefault();

    let dateObj = new Date(data.date);
    let momentObj = moment(dateObj);
    let momentString = momentObj.format('YYYY-MM-DD');

    let appointment = this.props.appointments.filter(function(app) {
      return app.date === momentString && app.starttime === data.time;
    });

    window.sessionStorage.setItem("selectedAppointment", JSON.stringify(appointment));
    window.sessionStorage.setItem("backButton", JSON.stringify(false));


    if (parseInt(window.sessionStorage.currentPage) === 5) {
      window.sessionStorage.setItem("backSkip", 5);
      window.sessionStorage.setItem("currentPage", 8);
      this.props.history.push(`/${this.props.match.params.id}/9`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/9`);
    }

  }

  setSelectedDate = selectedDate => {
    this.setState({ selectedDate })
  }

  getAllAppointments(data) {
    this.props.getAllAppointments(data)
    .then(() => {
      if (typeof this.props.appointments === "object" && this.props.appointments.length > 0) {
        this.setState({
          selectedDate: new Date(this.props.appointments[0].date.replace( /(\d{4})-(\d{2})-(\d{2})/, "$2/$3/$1")),
          loadingTimes: true
        })

        this.getAvailableTimes(this.props.appointments[0].date)
      } else {
        this.setState({
          selectedDate: null,
          loadingTimes: true
        })

        this.getAvailableTimes(null)
      }
    });
  }

  getNextMonth = month => {
    this.setState({
      selectedDate: null,
      loadingTimes: true
    });

    this.scrollToBottomTop();

    const momentDate = moment(month.activeStartDate);

    const appointmentIndex = this.props.config.appointments.findIndex(appointment => parseInt(appointment.appointment_id) === parseInt(window.sessionStorage.appointmentId));
    const appointment = this.props.config.appointments[appointmentIndex];

    let monthChoice = parseInt(momentDate.format("MM"));
    let year = parseInt(momentDate.format("YYYY"));
    let data;

    let providerId = window.sessionStorage.providerId;

    let providerIdx = this.props.config.providers.findIndex(providers => parseInt(providerId) === parseInt(providers.provider_assoc))
    let foundProvider = this.props.config.providers[providerIdx];


    if (foundProvider) {
       providerId = foundProvider.provider_id;
    }

    if (window.sessionStorage.locationId) {
      data = {
        client: window.sessionStorage.locationId,
        appointmentId: appointment.appointment_id,
        providerId: providerId,
        month: monthChoice,
        year: year
      }
    } else {
      data = {
        client: this.props.match.params.id,
        appointmentId: appointment.appointment_id,
        providerId: providerId,
        month: monthChoice,
        year: year
      }
    }

    this.getAllAppointments(data)
  }

    //  place span addition to appointment API endpoint
  // this function will be searching through available appointments on set date and returning available times
  // important to do
  getAvailableTimes = day => {
    const selectedDate = moment(day).format('YYYY-MM-DD');

    let times = [];
    let appointments;

    if (typeof this.props.appointments === "object" && this.props.appointments.length >= 1) {
      times = this.props.appointments.filter(appt => {
        return appt.date === selectedDate;
      })
    }

    let morning;
    let morningMinRangeString = "01:00:00".split(":");
    let morningMinRange = (+morningMinRangeString[0]) * 60 * 60 + (+morningMinRangeString[1]) * 60 + (+morningMinRangeString[2]);
    let morningMaxRangeString = "12:30:00".split(":");
    let morningMaxRange = (+morningMaxRangeString[0]) * 60 * 60 + (+morningMaxRangeString[1]) * 60 + (+morningMaxRangeString[2]);

    let afternoon;
    let afternoonMinRangeString = "12:30:00".split(":");
    let afternoonMinRange = (+afternoonMinRangeString[0]) * 60 * 60 + (+afternoonMinRangeString[1]) * 60 + (+afternoonMinRangeString[2]);
    let afternoonMaxRangeString = "17:00:00".split(":");
    let afternoonMaxRange = (+afternoonMaxRangeString[0]) * 60 * 60 + (+afternoonMaxRangeString[1]) * 60 + (+afternoonMaxRangeString[2]);

    let evening;
    let eveningMinRangeString = "17:00:00".split(":");
    let eveningMinRange = (+eveningMinRangeString[0]) * 60 * 60 + (+eveningMinRangeString[1]) * 60 + (+eveningMinRangeString[2]);
    let eveningMaxRangeString = "23:59:59".split(":");
    let eveningMaxRange = (+eveningMaxRangeString[0]) * 60 * 60 + (+eveningMaxRangeString[1]) * 60 + (+eveningMaxRangeString[2]);


    if (times.length > 0) {
      morning = times.filter(function(time) {
        time = time.starttime.split(":");
        time = (+time[0]) * 60 * 60 + (+time[1]) * 60 + (+time[2]);
        return (time >= morningMinRange && time < morningMaxRange);
      });

      afternoon = times.filter(function(time) {
        time = time.starttime.split(":");
        time = (+time[0]) * 60 * 60 + (+time[1]) * 60 + (+time[2]);
        return (time >= afternoonMinRange && time < afternoonMaxRange);
      });

      evening = times.filter(function(time) {
        time = time.starttime.split(":");
        time = (+time[0]) * 60 * 60 + (+time[1]) * 60 + (+time[2]);
        return (time >= eveningMinRange && time <= eveningMaxRange);
      });
    };

    appointments = [];

    if (morning && morning.length > 0) {
      morning = morning.filter((v,i,a)=>a.findIndex(t=>(t.starttime === v.starttime))===i)

      morning.forEach(time => {
        appointments.push(time.starttime);
      })
    }

    if (afternoon && afternoon.length > 0) {
      afternoon = afternoon.filter((v,i,a)=>a.findIndex(t=>(t.starttime === v.starttime))===i)

      afternoon.forEach(time => {
        appointments.push(time.starttime);
      })
    }

    if (evening && evening.length > 0) {
      evening = evening.filter((v,i,a)=>a.findIndex(t=>(t.starttime === v.starttime))===i)

      evening.forEach(time => {
        appointments.push(time.starttime);
      })
    }

    this.setState({
      loaded: true,
      loadingTimes: false,
      availableTimes: appointments
    }, () => {
        this.scrollToBottomTop();
    });
  }

  render() {
    const contrastColor = this.props.config.color ? this.props.config.color : "#2699fb";

    const Spacer = styled.div`
      width: ${props => props.width || 0};
      height: ${props => props.height || 0};
    `;

    if (!this.state.loaded) {
      return  <div className="optionsTwoLoader">
                <Spacer height="10px" />
                <PulseLoader color={"#707070"}  size={10} />
              </div>;
    }

    const Button = styled.button`
      background-color: white !important;
      color: #707070 !important;
      border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;

      &:hover {
        background-color: ${this.state.hover ? this.props.config.color : "#2699fb"} !important;
        color: ${this.state.hover ? getContrast(contrastColor) : "#707070"} !important;
        border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
      }
    `;

    const appointmentIndex = this.props.config.appointments.findIndex(appointment => parseInt(appointment.appointment_id) === parseInt(window.sessionStorage.appointmentId));
    const appointment = this.props.config.appointments[appointmentIndex];

    let allowed_days = [];

    let provider_allowed_days = [1,2,3,4,5,6,7]
    let firstPass = [];

    allowed_days = appointment.allowed_days;

    allowed_days.forEach(appt => {
      provider_allowed_days.forEach(day => {
        if (parseInt(appt) === parseInt(day)) {
          firstPass.push(day);
        }
      })
    })

    const { selectedDate, availableTimes, loadingTimes } = this.state;
    const formattedSelectedDate = moment(selectedDate).format('dddd, MMMM D');

    let dow = [];
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    firstPass.forEach(day => {
      if (daysOfWeek.indexOf(day)) {
        dow.push(daysOfWeek[day - 1]);
      }
    });

    let availTimesDiv;

    if (!loadingTimes && typeof this.props.appointments === "object" && this.props.appointments.length > 1) {
      availTimesDiv = "Please select a date";
    } else if (loadingTimes) {
      availTimesDiv = "Loading";
    } else {
      availTimesDiv = `No times available. Choose a different month or give us a call at ${this.props.config.practicePhone}`;
    }

    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let endDate = new Date(year + 1, month, day);
    let startdate;

    if (window.sessionStorage.firstAvailable) {
      startdate = moment(window.sessionStorage.firstAvailable).toDate();
    } else {
      startdate = new Date(year, month, 1);
    };

    return (
      <Wrapper id="availTimes" color={this.props.config.color ? this.props.config.color : "#007bff"}>
        <h2 className="title">
          Ok, let&apos;s compare calendars.
        </h2>
        <h3 className="subtitle">
          Do any of these work for you?
        </h3>

        <div ref={el => { this.el = el; }}>
          <ContentWrapper>
            <Calendar
              onChange={this.setSelectedDate}
              value={selectedDate}
              calendarType="US"
              className="calendar"
              onClickDay={this.getAvailableTimes}
              onActiveStartDateChange={this.getNextMonth.bind(this)}
              tileClassName="tile available"
              maxDate={endDate}
              tileDisabled={(activeStartDate, date, view) => {
                const momentDate = moment(activeStartDate.date);
                const dayOfWeek = momentDate.format('ddd');

                if (momentDate.isBefore(today, 'day')) {
                  return true;
                }

                if (!dow.includes(dayOfWeek)) {
                  return true;
                }
                let test = momentDate.format("YYYY-MM-DD");
                let appoints;

                if (typeof this.props.appointments === "object" && this.props.appointments.length > 1) {
                  appoints = this.props.appointments.filter(function(app) {
                    return app.date === test && allowed_days.includes(app.dow);
                  });

                  if (appoints && appoints.length < 1) {
                    return true;
                  }
                } else {
                  return true;
                }
              }}
              minDate={startdate}
              minDetail="month"
              formatMonthYear={(locale, date) => moment(date).format("MMMM YYYY")}
              formatShortWeekday={(locale, date) => moment(date).format('dd').charAt(0)}
              prevLabel={<ArrowLeft />}
              nextLabel={<ArrowRight />}
              prev2Label={null}
              next2Label={null}
            />

            {selectedDate ? (
              <div className="timesContainer">
                <h2 className="selectedDateTitle">
                  {formattedSelectedDate}
                </h2>
                <AvailableTimes className="avail" dateSelected>

                  {loadingTimes && (
                    <>
                      <Spacer height="10px" />
                      <PulseLoader  size={10} />
                    </>
                  )}
                  {
                    !loadingTimes
                    && availableTimes
                    && availableTimes.length > 0
                    && availableTimes.map((apptTime, index) => (
                      <Button onClick={this.handleAppointment({date: selectedDate, time: apptTime})} className="apptTimes btn btn-primary" key={index}>{moment(apptTime, "hh:mm:ss").format("h:mma")}</Button>
                    ))
                  }
                </AvailableTimes>
              </div>
            ) : (
              <div className="timesContainer">
                <h2 className="selectedDateTitle">
                  {availTimesDiv}
                </h2>
                <AvailableTimes className="avail" dateSelected>

                  {loadingTimes && (
                    <>
                      <Spacer height="10px" />
                      <PulseLoader  size={10} />
                    </>
                  )}
                  {
                    !loadingTimes
                    && availableTimes
                    && availableTimes.length > 0
                    && availableTimes.map((apptTime, index) => (
                      <Button onClick={this.handleAppointment({date: selectedDate, time: apptTime})} className="apptTimes btn btn-primary" key={index}>{moment(apptTime, "hh:mm:ss").format("h:mma")}</Button>
                    ))
                  }
                </AvailableTimes>
              </div>
            )}
          </ContentWrapper>
        </div>
      </Wrapper>
    );
  }
}

export default CompareCalendars;
