import React from 'react';
import './schedule_appointment.scss';
import moment from 'moment';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import _ from 'lodash';
import Modal from './schedule_appointment_modal_container';
import $ from "jquery";
import { PulseLoader } from 'react-spinners';
import { getContrast } from '../../styles/contrast';
import RadioQuestion from './inputs/radio';
import DropdownQuestion from './inputs/dropdown';
import TextQuestion from './inputs/text';

import FlexIcon from '../../styles/flex_icon';

const SingleValue = ({ children, ...props }) => (
   <components.SingleValue {...props}>
     {children}
   </components.SingleValue>
 );

class ScheduleAppointment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fname: "",
      lname: "",
      dob: "",
      email: "",
      phone: "",
      hasInsurance: "",
      insurance: "",
      insuranceOther: "No",
      selectedOption: null,
      showInsuranceSelection: false,
      showInsuranceMessage: false,
      showNoInsuranceMessage: false,
      show: false,
      insurance_change: false,
      showCodeAlert: "show",
      subscriber_id: "",
      loading: false,
      returning: window.sessionStorage.patientType,
      hover: JSON.parse(window.sessionStorage.phoneType)
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDob = this.handleDob.bind(this)
    this.handleEmail = this.handleEmail.bind(this)
    this.handlePhone = this.handlePhone.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.handleInsurance = this.handleInsurance.bind(this);
    this.updateInsurance = this.updateInsurance.bind(this);
    this.updateReturningInsurance = this.updateReturningInsurance.bind(this);
    this.doNotUpdateReturningInsurance = this.doNotUpdateReturningInsurance.bind(this);
    this.buttonSelected = this.buttonSelected.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    const scrolly = document.getElementsByClassName("scheduleAppointmentContainer");
    scrolly[0].scrollIntoView({ behavior: 'smooth', block: "start" });

    const currentPage = window.sessionStorage.currentPage;

    if (window.location !== window.parent.location) {
      if (parseInt(currentPage) === 8) {
        window.sessionStorage.setItem("currentPage", 9);
      } else if (parseInt(currentPage) === 10) {
        if (this.props.match.params.analytics !== undefined) {
          this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        } else {
          this.props.history.push(`/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        }
      } else {
        if (this.props.match.params.analytics !== undefined) {
          this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        } else {
          this.props.history.push(`/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        }
      }
    } else if (parseInt(currentPage) === 10) {
      if (this.props.match.params.analytics !== undefined) {
        this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
      } else {
        this.props.history.push(`/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
      }

      window.securedNav = false;
    } else {
      if (parseInt(currentPage) === 8 || parseInt(currentPage) === 9) {
        window.sessionStorage.setItem("currentPage", 9);
      } else {
        if (window.securedNav === true && parseInt(currentPage) === 2) {
          window.sessionStorage.setItem("currentPage", 1);
        } else {
          if (this.props.match.params.analytics !== undefined) {
            this.props.history.replace(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? parseInt(currentPage) - 1 : ""}`);
          } else {
            this.props.history.replace(`/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? parseInt(currentPage) - 1 : ""}`);
          }
        }
      }

      window.securedNav = false;
    }

    let returning = this.state.returning;

    if (returning !== "new") {
      this.setState({
        insurance: "already provided"
      });
    }
  }

  handleKeyPress(value, event) {
    if (event.key === 'Enter') {
      const arr = ["fname", "lname", "email", "month", "day", "year", "phone"];

      const nextIndex = arr.indexOf(value) + 1;

      if (nextIndex >= arr.length) {
        return;
      }

      this.refs[arr[nextIndex]].focus();
    }
  }

  filterValue(obj, key, value) {
    return obj.find(function(v){ return v[key] === value});
  }

  componentDidUpdate(prevProps) {
     if (prevProps.verify !== this.props.verify && this.props.verify === "approved") {

       const appointmentId = window.sessionStorage.appointmentId;
       let scheduleApp = JSON.parse(window.sessionStorage.selectedAppointment);
       let returning = this.state.returning;
       let insurance_change = false;

       let providerId = window.sessionStorage.providerId;

       let providerIdx = this.props.config.providers.findIndex(providers => parseInt(providerId) === parseInt(providers.provider_assoc))
       let foundProvider = this.props.config.providers[providerIdx];


       if (foundProvider) {
          providerId = foundProvider.provider_id;
       }

       if (returning === "new") {
         returning = false;
       } else if (returning === "returningUpdate") {
         returning = true;
         insurance_change = true;
       } else {
         returning = true;
       }

       if (typeof scheduleApp === "object" && scheduleApp.length > 0) {
         scheduleApp = scheduleApp[0];
       }

       let client;

       if (window.sessionStorage.locationId) {
         client = window.sessionStorage.locationId;
       } else {
         client = this.props.match.params.id;
       }

       let insurance;

       if (this.state.hasInsurance === "Yes" && this.state.selectedOption.label && this.state.selectedOption.label !== "Other") {
         insurance = this.state.selectedOption.label;
       } else {
         insurance = this.state.insuranceOther;
       }

       let number = this.state.phone.replace(/[^\d]/g, '');

       var qAs = [];

       var questions = this.props.config.questions !== undefined ? this.props.config.questions : [];

       questions.forEach(el => {
         var qA = {'q': el.question, 'a': this.state[`question-${el.id}`] !== undefined ? this.state[`question-${el.id}`] : ""};
         qAs.push(qA);
       });

       let data = {
         client: client,
         fname: this.state.fname,
         lname: this.state.lname,
         email: this.state.email,
         dob: this.state.dob,
         phone: number,
         appointment_id: appointmentId,
         appointment_date: scheduleApp.date,
         appointment_time: scheduleApp.starttime,
         operatory: scheduleApp.operatory,
         provider_id: providerId,
         returning: returning,
         insurance: insurance,
         insuranceChange: insurance_change,
         subscriber_id: this.state.subscriber_id !== "" ? this.state.subscriber_id : "0",
         tracking: this.props.match.params.analytics,
         qas: qAs
       }

       this.props.createAppointment(data)
       .then(() => {
         window.sessionStorage.setItem("created", this.props.appointments);

         window.sessionStorage.setItem("currentPage", 9);
         this.createCookieInHour(this.state.phone, 'approved', 1);

         this.setState({
           loading: false,
           show: !this.state.show
         }, () => {
           if (this.props.match.params.analytics !== undefined) {
             this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/10`);
           } else {
             this.props.history.push(`/${this.props.match.params.id}/10`);
           }
         })
       });
     }
   }

  showModal = e => {
    this.setState({
      show: !this.state.show
    }, () => {
      if (document.getElementById("modal")) {
          this.scrollToModal();
      }
    });

    if (!this.state.show) {
      const background = document.getElementsByClassName("blurry");
      $(background).attr('style',  'filter: blur(5px);');
    } else {
      const background = document.getElementsByClassName("blurry");
      $(background).attr('style',  'filter: none');
    }
  };

  getCookie(name) {
    let dc = document.cookie;
    let prefix = name + "=";
    let begin = dc.indexOf("; " + prefix);
    let end;

    if (parseInt(begin) === -1) {
        begin = dc.indexOf(prefix);
        if (begin !== 0) return null;
    } else {
        begin += 2;
        end = document.cookie.indexOf(";", begin);

        if (parseInt(end) === -1) {
          end = dc.length;
        }
    }

    return decodeURI(dc.substring(begin + prefix.length, end));
  }

  update(field) {
    return e => {
        this.setState({ [field]: e.target.value})
    };
  };

  scrollToBottom() {
    if (JSON.parse(window.sessionStorage.phoneType)) this.el.getElementsByTagName('button')[0].scrollIntoView({ behavior: 'smooth' });
  };

  scrollToBottomPage() {
    const first = document.getElementById("scrollTop");
    if (JSON.parse(window.sessionStorage.phoneType)) first.scrollIntoView({ behavior: 'smooth', block: "end" });
  };

  scrollToFirst() {
    const first = document.getElementById("firstInput");
    if (JSON.parse(window.sessionStorage.phoneType)) first.scrollIntoView({ behavior: 'smooth', block: "center" });
  };

  scrollToLast() {
    const last = document.getElementById("lastInput");
    if (JSON.parse(window.sessionStorage.phoneType)) last.scrollIntoView({ behavior: 'smooth', block: "center" });
  };

  scrollToEmail() {
    const email = document.getElementById("emailInput");
    if (JSON.parse(window.sessionStorage.phoneType)) email.scrollIntoView({ behavior: 'smooth', block: "center" });
  };

  scrollToPhone() {
    const phone = document.getElementById("phoneInput");
    if (JSON.parse(window.sessionStorage.phoneType)) phone.scrollIntoView({ behavior: 'smooth', block: "center" });
  };

  scrollToModal() {
    const modal = document.getElementById("modal");
    if (JSON.parse(window.sessionStorage.phoneType)) modal.scrollIntoView({ behavior: 'smooth', block: "center" });
  };

  handleDob(e){
    let month = document.getElementById('date-of-birth-month')
    let day = document.getElementById('date-of-birth-day')
    let year = document.getElementById('date-of-birth-year')
    if (!day.value || !year.value) {
      this.setState({dob:''})
      this.update("dob")
      return
    }
    const now = moment();
    if (day.value && day.value <1) day.value = 1
    if (day.value && day.value >31) day.value = 31
    if (year.value && year.value.length >=4 && year.value < 1900) year.value = 1900
    if (year.value && year.value.length >= 4 && year.value > now.year()) year.value = now.year()
    if (year.value.length <4) {
      this.setState({dob:''})
      this.update("dob")
      return
    }
    let dayVal = day.value

    let monthVal = month.options[month.selectedIndex].value;
    if (monthVal.length === 1) monthVal = '0'+monthVal
    if (dayVal.length === 1) dayVal = '0'+day.value
    let dobVal = year.value+'-'+monthVal+'-'+dayVal
    if (moment(dobVal, 'YYYY-MM-DD',true).isValid()) {
      this.setState({dob:dobVal})
      this.update("dob")
      day.classList.remove('invalid')
    }
    else{
      this.setState({dob:''})
      this.update("dob")
      day.classList.add('invalid')
    }
  };

  handleEmail(){
    let email = document.getElementById('email');
    if (email.value.length < 5 || !email.value.includes("@") || !email.value.includes(".") ) {
      email.classList.add('invalid');
      this.setState({ email: email.value });
    } else {
      this.setState({ email: email.value });
      this.update("email")
      email.classList.remove('invalid')
    }
  };

  handleSubmit(e) {
    e.preventDefault();

    let data = {};

    var qAs = [];

    var questions = this.props.config.questions !== undefined ? this.props.config.questions : [];

    questions.forEach(el => {
      var qA = {'q': el.question, 'a': this.state[`question-${el.id}`] !== undefined ? this.state[`question-${el.id}`] : ""};
      qAs.push(qA);
    });

    const myCookie = this.getCookie(this.state.phone);
    const appointmentId = window.sessionStorage.appointmentId;
    let scheduleApp = JSON.parse(window.sessionStorage.selectedAppointment);
    let returning = this.state.returning;
    let insurance_change = false;

    let providerId = window.sessionStorage.providerId;

    let providerIdx = this.props.config.providers.findIndex(providers => parseInt(providerId) === parseInt(providers.provider_assoc))
    let foundProvider = this.props.config.providers[providerIdx];


    if (foundProvider) {
       providerId = foundProvider.provider_id;
    }

    if (returning === "new") {
      returning = false;
    } else if (returning === "returningUpdate") {
      returning = true;
      insurance_change = true;
    } else {
      returning = true;
    }

    if (typeof scheduleApp === "object" && scheduleApp.length > 0) {
      scheduleApp = scheduleApp[0];
    }

    let client;

    if (window.sessionStorage.locationId) {
      client = window.sessionStorage.locationId;
    } else {
      client = this.props.match.params.id;
    }

    let insurance;

    if (this.state.hasInsurance === "Yes" && this.state.selectedOption.label && this.state.selectedOption.label !== "Other") {
      insurance = this.state.selectedOption.label;
    } else {
      insurance = this.state.insuranceOther;
    }

    let number = this.state.phone.replace(/[^\d]/g, '');

    data = {
      client: client,
      fname: this.state.fname,
      lname: this.state.lname,
      email: this.state.email,
      dob: this.state.dob,
      phone: number,
      appointment_id: appointmentId,
      appointment_date: scheduleApp.date,
      appointment_time: scheduleApp.starttime,
      operatory: scheduleApp.operatory,
      provider_id: providerId,
      returning: returning,
      insurance: insurance,
      insuranceChange: insurance_change,
      subscriber_id: this.state.subscriber_id !== "" ? this.state.subscriber_id : "0",
      tracking: this.props.match.params.analytics,
      qas: qAs
    }

    if (myCookie) {
      if (myCookie === "approved" || this.props.config.verification === 0) {
        this.setState({ loading: true });

        this.props.createAppointment(data)
        .then(() => {
          window.sessionStorage.setItem("created", this.props.appointments);

          this.setState({ loading: false });
          window.sessionStorage.removeItem("backPage");
          window.sessionStorage.setItem("currentPage", 9);

          if (this.props.match.params.analytics !== undefined) {
            this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/10`);
          } else {
            this.props.history.push(`/${this.props.match.params.id}/10`);
          }
        })
      } else {
        this.showModal();

        // this is used to handle tracking insurance use. will display insurance selection on next appointment creation, or button to change
        this.props.sendVerification(this.state.phone);
      }
    } else {
        this.showModal();

      // this is used to handle tracking insurance use. will display insurance selection on next appointment creation, or button to change
      this.props.sendVerification(this.state.phone);
    }
  };

  handleInsurance(selectedOption) {
    this.setState({
      selectedOption,
      showInsuranceMessage: true
    });

    if (selectedOption.label === "Other") {
      this.setState({
        showInsuranceField: true
      }, () => {
        this.scrollToBottom();
      })
    }
  }

  handlePhone(phoneNumber) {
    let ph = phoneNumber.currentTarget.value.replace(/\D/g,'').substring(0,10);

    let len = parseInt(ph.length);

    if (len < 3) {
        ph = '(' + ph;
    } else if (len === 3){
        ph = '(' + ph + ') ';
    } else if (len < 6) {
        ph = '(' + ph.substring(0,3) + ') ' + ph.substring(3,6);
    } else if (len === 6) {
        ph = '(' + ph.substring(0,3) + ') ' + ph.substring(3,6) + ('-');
    } else {
        ph = '(' + ph.substring(0,3) + ') ' + ph.substring(3,6) + '-' + ph.substring(6,10);
    }

    this.setState({
      phone: ph
    })
  }

  onKeyPress(e) {
    let ph = e.target.value.replace(/\D/g,'').substring(0,10);

    let deleteKey = (e.key === "Backspace");
    let len = parseInt(ph.length);

    if (len < 3){
        ph = '(' + ph;
    } else if (len === 3) {
        ph = '(' + ph + (deleteKey ? '' : ') ');
    } else if (len < 6) {
        ph = '(' + ph.substring(0,3) + ') ' + ph.substring(3,6);
    }else if (len === 6) {
        ph = '(' + ph.substring(0,3) + ') ' + ph.substring(3,6) +  (deleteKey ? '' : '-');
    }else{
        ph = '(' + ph.substring(0,3) + ') ' + ph.substring(3,6) + '-' + ph.substring(6,10);
    }

    this.setState({
      phone: ph
    })

  }

  updateInsurance(e) {
    e.preventDefault();

    this.setState({
      insurance: "",
      selectedOption: null
    }, () => {
      this.setState(this.state);
    });
  }

  selectionChanged = type => ev => {
    this.setState({ [type]: ev.target.value })
  }

  dropdownChanged = type => ev => {
    this.setState({ [type]: ev.label })
  }

  updateReturningInsurance(e) {
    e.preventDefault();

    this.setState({
      hasInsurance: "",
      insurance_change: true,
      insurance: "",
      selectedOption: null,
      returning: "returningUpdate"
    });
  }

  doNotUpdateReturningInsurance(e) {
    e.preventDefault();

    this.setState({
      hasInsurance: "Yes",
      insurance_change: false,
      insurance: "No",
      selectedOption: "already provided",
      returning: ""
    });

    this.scrollToBottom()
  }

  createCookieInHour = (cookieName, cookieValue, hourToExpire) => {
    let date = new Date();
    date.setTime(date.getTime()+(hourToExpire*60*60*1000));
    document.cookie = cookieName + " = " + cookieValue + "; expires = " +date.toGMTString();
  }

  buttonSelected = selectedButton => ev => {
    this.setState({
      insurance: selectedButton
    });

    if (selectedButton === "Yes") {
      this.setState({
        hasInsurance: selectedButton,
        selectedOption: null,
        showInsuranceSelection: true,
        showNoInsuranceMessage: false
      });
    } else {
      this.setState({
        hasInsurance: selectedButton,
        selectedOption: "already provided",
        showInsuranceSelection: false,
        showNoInsuranceMessage: true,
        subscriber_id: ""
      });
    }

    this.scrollToBottom();
  }

  render() {
    var questions = this.props.config.questions !== undefined ? this.props.config.questions : [];

    const scroll = document.getElementsByClassName("scheduleAppointmentContainer");

    if (this.state.hasInsurance === "Yes") {
      if (window.innerHeight > 728 && window.innerHeight < 890 && JSON.parse(window.sessionStorage.phoneType)) {
        $(scroll).attr('style', 'margin-top: 10%');
      } else {
        $(scroll).attr('style', 'margin-top: 0% !important');
        $(scroll).attr('style', 'padding-top: 0% !important');
      }
    } else {
      $(scroll).attr('style', 'margin-top: 0% !important');
      $(scroll).attr('style', 'padding-top: 0% !important');
    }

    const contrastColor = this.props.config.color ? this.props.config.color : "#2699fb";

    const Spacer = styled.div`
      width: ${props => props.width || 0};
      height: ${props => props.height || 0};
    `;

    const backButton = document.getElementsByClassName("backButton");

    if (this.state.loading) {
      $(backButton).addClass("hideButton");
    } else {
      $(backButton).removeClass("hideButton");
    }

    const { selectedOption } = this.state;

    const { fname, lname, dob, email, phone, insurance, subscriber_id } = this.state;

    const myCookie = this.getCookie(phone);

    let selectedAppointment;

    if (window.sessionStorage.selectedAppointment) {
      selectedAppointment = JSON.parse(window.sessionStorage.selectedAppointment);
    }

    if (typeof selectedAppointment === "object" && selectedAppointment.length > 0) {
      selectedAppointment = selectedAppointment[0];
    }

    let provider;
    let providerId;

    if (parseInt(window.sessionStorage.providerId) !== 0) {
      let providerIndex = this.props.config.providers.findIndex(provider => parseInt(provider.provider_assoc) === parseInt(window.sessionStorage.providerId));
      provider = this.props.config.providers[providerIndex];
    }

    const { practicePhone } = this.props.config;

    const activeInsuranceOptions = this.props.config.insurance[0].active;
    const inNetworkInsuranceOptions = this.props.config.insurance[1].inNetwork;

    let allInsuranceOptions = _.unionBy(activeInsuranceOptions, inNetworkInsuranceOptions, 'label');
    allInsuranceOptions.push({value: 0, label: "Other"});

    const insuranceMessages = this.props.config.messages;

    let regex = "{{practicephone}}";
    let providerRegex = "{{provider}}";
    let takesRegex = "takes";
    let insuranceVerbLinkRegex = " is in ";

    let insuranceMessage;
    let insuranceMsgProvider;
    let insuranceTakesWord;
    let insuranceVerbLink;

    if (provider) {
        insuranceMsgProvider = provider.name;
        insuranceTakesWord = "takes";
        insuranceVerbLink = " is in ";
    } else {
      insuranceMsgProvider = "We";
      insuranceTakesWord = "take";
      insuranceVerbLink = " are in ";
    }

    if (this.state.showNoInsuranceMessage) {
      insuranceMessage = <div id="insuranceId" ref={el => { this.el = el; }} className="insuranceMessage"> {this.filterValue(insuranceMessages, "type", "noinsurance").message.replace(regex, practicePhone).replace(providerRegex, insuranceMsgProvider).replace(takesRegex, insuranceTakesWord).replace(insuranceVerbLinkRegex, insuranceVerbLink)} </div>;
    } else {
      if (this.state.showInsuranceMessage) {
        if (_.find(inNetworkInsuranceOptions, this.state.selectedOption)) {
          insuranceMessage = <div id="insuranceId" ref={el => { this.el = el; }} className="insuranceMessage"> {this.filterValue(insuranceMessages, "type", "innetwork").message.replace(regex, practicePhone).replace(providerRegex, insuranceMsgProvider).replace(takesRegex, insuranceTakesWord).replace(insuranceVerbLinkRegex, insuranceVerbLink)} </div>;
        } else if (_.find(activeInsuranceOptions, this.state.selectedOption)) {
          insuranceMessage = <div id="insuranceId" ref={el => { this.el = el; }} className="insuranceMessage"> {this.filterValue(insuranceMessages, "type", "outnetwork").message.replace(regex, practicePhone).replace(providerRegex, insuranceMsgProvider).replace(takesRegex, insuranceTakesWord).replace(insuranceVerbLinkRegex, insuranceVerbLink)} </div>;
        } else {
          insuranceMessage = <div id="insuranceId" ref={el => { this.el = el; }} className="insuranceMessage"> {this.filterValue(insuranceMessages, "type", "other").message.replace(regex, practicePhone).replace(providerRegex, insuranceMsgProvider).replace(takesRegex, insuranceTakesWord).replace(insuranceVerbLinkRegex, insuranceVerbLink)} </div>;
        }
      } else {
        insuranceMessage = <div id="insuranceId" ref={el => { this.el = el; }} className="insuranceMessage"></div>;
      }
    }

    let insuranceSelection;

    if (this.state.showInsuranceSelection) {
      insuranceSelection = <div className="row justify-content-center"><div className="insuranceSelection col-sm-12 col-md-8"><Select maxMenuHeight={225} isClearable={false} isSearchable={false} onChange={this.handleInsurance} components={{ SingleValue }} options={allInsuranceOptions} /></div></div>;
    }

    let ins = false;


    if (insurance.length !== 0 && selectedOption !== null && subscriber_id.length > 1) {
      ins = true;
    } else if (insurance === "No" && selectedOption !== null) {
      ins = true;
    }

    let sendCodeAlert;

    const ContinueButton = styled.button`
      border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
      background-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
      color: ${getContrast(contrastColor)} !important;
      font-weight: bold !important;

      &:hover {
        border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
        background-color: ${this.state.hover ? this.props.config.color : "white"} !important;
        color: ${this.state.hover ? getContrast(contrastColor) : "#707070" } !important;
      }
    `;

    const Button = styled.button`
      background-color: white !important;
      color: #707070 !important;
      border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
      border: 1px solid ${this.props.config.color ? this.props.config.color : "#2699fb"};

      &:hover {
        background-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
        color: ${getContrast(contrastColor)} !important;
        border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
      }

      &.selected {
        background-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
        color: ${getContrast(contrastColor)} !important;
        border-color: ${this.props.config.color ? this.props.config.color : "#2699fb"} !important;
      }
    `;

    let insuranceFillField;
    let subscriberFillField;

    if (myCookie && myCookie === "approved") {
      sendCodeAlert = <div className={`col col-12 codeAlert ${this.state.showCodeAlert}`}>You have already confirmed your identity</div>;
    } else if (this.props.config.verification === 0){
      sendCodeAlert = <div className={`col col-12 codeAlert ${this.state.showCodeAlert}`}></div>;
    } else {
      sendCodeAlert = <div className={`col col-12 codeAlert ${this.state.showCodeAlert}`}>We’ll text you a 6-digit code to confirm this appointment.</div>;
    }

    let insuranceDiv;
    const returning = this.state.returning;

    let questionsDiv = [];

    let questionsPlaces = [];

    var topQuestions = [];

    if (returning !== "new") {
      questions.forEach((ele, i) => {
        if (ele.type === 1 && ele.condition === 3) {
          questionsPlaces.push(ele);
          topQuestions.push(<TextQuestion dob={this.state.dob} onChange={this.update(`question-${ele.id}`)} value={this.state[`${ele}`]} refs={`textInput${ele.id}`} disabled={this.state.show} key={i} data={ele} />);
        } else if (ele.type === 2 && ele.condition === 3) {
          questionsPlaces.push(ele);
          topQuestions.push(<RadioQuestion dob={this.state.dob} state={this.state} onChange={this.selectionChanged(`question-${ele.id}`)} key={i} data={ele} />);
        } else if (ele.type === 3 && ele.condition === 3) {
          questionsPlaces.push(ele);
          topQuestions.push(<DropdownQuestion dob={this.state.dob} onChange={this.dropdownChanged(`question-${ele.id}`)} key={i} data={ele} />);
        }
      });
    }

    if (returning === "returning" || returning === "") {
      insuranceDiv =  <div className="insContainer">
                        <div className="insuranceDescription">
                          Has your insurance changed since you last visited us?
                        </div>

                        <Button className="insurance updateInsurance" type="button" onClick={this.updateReturningInsurance}>Yes</Button>
                        <Button className={`insurance updateInsurance ${"Yes" === this.state.hasInsurance ? 'selected' : ''}`} type="button" onClick={this.doNotUpdateReturningInsurance}>No</Button>
                      </div>;
    } else {
      if (this.state.insurance && this.state.insurance === "No" && returning === "returning") {
        // returning that do not have insurance
        // questionsDiv = [];
        // questionsPlaces = [];

        insuranceDiv =  <div className="insContainer">
                          <div className="insuranceDescription">
                            Our records show that you do not have insurance.
                          </div>

                          <Button className="insurance updateInsurance" type="button" onClick={this.updateInsurance}>Update</Button>
                        </div>;

      } else if (this.state.insurance && this.state.insurance !== "No" && returning === "returning") {
        // returning that have insurance
        // questionsDiv = [];
        // questionsPlaces = [];

        questions.forEach((ele, i) => {
          if (ele.type === 1 && ele.condition === 6) {
            questionsPlaces.push(ele);
            questionsDiv.push(<TextQuestion dob={this.state.dob} onChange={this.update(`question-${ele.id}`)} value={this.state[`${ele}`]} refs={`textInput${ele.id}`} disabled={this.state.show} key={i} data={ele} />);
          } else if (ele.type === 2 && ele.condition === 6) {
            questionsPlaces.push(ele);
            questionsDiv.push(<RadioQuestion dob={this.state.dob} state={this.state} onChange={this.selectionChanged(`question-${ele.id}`)} key={i} data={ele} />);
          } else if (ele.type === 3 && ele.condition === 6) {
            questionsPlaces.push(ele);
            questionsDiv.push(<DropdownQuestion dob={this.state.dob} onChange={this.dropdownChanged(`question-${ele.id}`)} key={i} data={ele} />);
          }
        });

        insuranceDiv =  <div className="insContainer">
                          <div className="insuranceDescription">
                            Our records show that you have {this.state.insurance !== "Yes" ? this.state.insurance : this.state.selectedOption.label} insurance.
                          </div>

                          <Button className="insurance updateInsurance" type="button" onClick={this.updateInsurance}>Update</Button>
                        </div>;
      } else {
        // new that have insurance
        // questionsDiv = [];
        // questionsPlaces = [];

        if (this.state.insurance === "Yes" && returning !== "returningUpdate") {
          questions.forEach((ele, i) => {
            if (ele.type === 1 && ele.condition === 4) {
              questionsPlaces.push(ele);
              questionsDiv.push(<TextQuestion dob={this.state.dob} onChange={this.update(`question-${ele.id}`)} value={this.state[`${ele}`]} refs={`textInput${ele.id}`} disabled={this.state.show} key={i} data={ele} />);
            } else if (ele.type === 2 && ele.condition === 4) {
              questionsPlaces.push(ele);
              questionsDiv.push(<RadioQuestion dob={this.state.dob} state={this.state} onChange={this.selectionChanged(`question-${ele.id}`)} key={i} data={ele} />);
            } else if (ele.type === 3 && ele.condition === 4) {
              questionsPlaces.push(ele);
              questionsDiv.push(<DropdownQuestion dob={this.state.dob} onChange={this.dropdownChanged(`question-${ele.id}`)} key={i} data={ele} />);
            }
          });
        } else if (this.state.insurance === "No" && returning !== "returningUpdate") {
          // new that do not have insurance
          // questionsDiv = [];
          // questionsPlaces = [];

          questions.forEach((ele, i) => {
            if (ele.type === 1 && ele.condition === 5) {
              questionsPlaces.push(ele);
              questionsDiv.push(<TextQuestion dob={this.state.dob} onChange={this.update(`question-${ele.id}`)} value={this.state[`${ele}`]} handleKeyPress={(event) => this.handleKeyPress(`textInput${ele.id}`, event)} refs={`textInput${ele.id}`} onKeyDown={this.onKeyPress} disabled={this.state.show} key={i} data={ele} />);
            } else if (ele.type === 2 && ele.condition === 5) {
              questionsPlaces.push(ele);
              questionsDiv.push(<RadioQuestion dob={this.state.dob} state={this.state} onChange={this.selectionChanged(`question-${ele.id}`)} key={i} data={ele} />);
            } else if (ele.type === 3 && ele.condition === 5) {
              questionsPlaces.push(ele);
              questionsDiv.push(<DropdownQuestion dob={this.state.dob} onChange={this.dropdownChanged(`question-${ele.id}`)} key={i} data={ele} />);
            }
          });
        }


        if (returning === "new") {
          questions.forEach((ele, i) => {
            if (ele.type === 1 && ele.condition === 2) {
              questionsPlaces.push(ele);
              topQuestions.push(<TextQuestion dob={this.state.dob} onChange={this.update(`question-${ele.id}`)} value={this.state[`${ele}`]} refs={`textInput${ele.id}`} disabled={this.state.show} key={i} data={ele} />);
            } else if (ele.type === 2 && ele.condition === 2) {
              questionsPlaces.push(ele);
              topQuestions.push(<RadioQuestion dob={this.state.dob} state={this.state} onChange={this.selectionChanged(`question-${ele.id}`)} key={i} data={ele} />);
            } else if (ele.type === 3 && ele.condition === 2) {
              questionsPlaces.push(ele);
              topQuestions.push(<DropdownQuestion dob={this.state.dob} onChange={this.dropdownChanged(`question-${ele.id}`)} key={i} data={ele} />);
            }
          });
        }

        if (returning === "returningUpdate" && this.state.insurance === "Yes") {
          questions.forEach((ele, i) => {
            if (ele.type === 1 && ele.condition === 6) {
              questionsPlaces.push(ele);
              questionsDiv.push(<TextQuestion dob={this.state.dob} onChange={this.update(`question-${ele.id}`)} value={this.state[`${ele}`]} refs={`textInput${ele.id}`} disabled={this.state.show} key={i} data={ele} />);
            } else if (ele.type === 2 && ele.condition === 6) {
              questionsPlaces.push(ele);
              questionsDiv.push(<RadioQuestion dob={this.state.dob} state={this.state} onChange={this.selectionChanged(`question-${ele.id}`)} key={i} data={ele} />);
            } else if (ele.type === 3 && ele.condition === 6) {
              questionsPlaces.push(ele);
              questionsDiv.push(<DropdownQuestion dob={this.state.dob} onChange={this.dropdownChanged(`question-${ele.id}`)} key={i} data={ele} />);
            }
          });
        }

        if (returning === "returningUpdate" && this.state.insurance === "No") {
          questions.forEach((ele, i) => {
            if (ele.type === 1 && ele.condition === 7) {
              questionsPlaces.push(ele);
              questionsDiv.push(<TextQuestion dob={this.state.dob} onChange={this.update(`question-${ele.id}`)} value={this.state[`${ele}`]} refs={`textInput${ele.id}`} disabled={this.state.show} key={i} data={ele} />);
            } else if (ele.type === 2 && ele.condition === 7) {
              questionsPlaces.push(ele);
              questionsDiv.push(<RadioQuestion dob={this.state.dob} state={this.state} onChange={this.selectionChanged(`question-${ele.id}`)} key={i} data={ele} />);
            } else if (ele.type === 3 && ele.condition === 7) {
              questionsPlaces.push(ele);
              questionsDiv.push(<DropdownQuestion dob={this.state.dob} onChange={this.dropdownChanged(`question-${ele.id}`)} key={i} data={ele} />);
            }
          });
        }

        if (selectedOption !== null && selectedOption.label === "Other") {
            insuranceFillField = <div className="row justify-content-center">
                                <div className="insuranceInput field col-sm-12 col-md-8">
                                  <input disabled={this.state.show} maxLength="75" onChange={this.update("insuranceOther")} type="name" className="form-control" id="name" placeholder="Insurance Provider"/>
                                </div>
                              </div>;

            subscriberFillField = <div className="row justify-content-center">
                                <div className="subscriberInput field col-sm-12 col-md-8">
                                  <input disabled={this.state.show} onChange={this.update("subscriber_id")} type="name" className="form-control" id="name" placeholder="Subscriber ID #"/>
                                </div>
                              </div>;
        } else if (selectedOption !== null && allInsuranceOptions.includes(selectedOption)) {
          subscriberFillField = <div className="row justify-content-center">
                              <div className="subscriberInput field col-sm-12 col-md-8">
                                <input disabled={this.state.show} onChange={this.update("subscriber_id")} type="name" className="form-control" id="name" placeholder="Enter Subscriber ID*"/>
                              </div>
                            </div>;
        } else {
          insuranceFillField = <div className="row justify-content-center"></div>;

        }

        insuranceDiv =  <div className="insContainer">
                          <div className="insuranceDescription">
                            Do you have dental insurance?
                          </div>
                          {['No', 'Yes'].map(key =>
                            <Button disabled={this.state.show} className={`insurance ${key === this.state.hasInsurance ? 'selected' : ''}`} type="button" key={key} onClick={this.buttonSelected(key)}>{key}</Button>
                          )}
                        </div>;
      }
    }

    let providerName = '';

    const appointmentIndex = this.props.config.appointments.findIndex(appointment => parseInt(appointment.appointment_id) === parseInt(window.sessionStorage.appointmentId));
    const appointment = this.props.config.appointments[appointmentIndex];

    window.sessionStorage.setItem("confirmedAppt", JSON.stringify(appointment));

    if (appointment && appointment.provider_select !== 0) {
      if (providerId && providerId > 0) {
        providerName = `with ${provider.name}`;
      } else {
        providerName = `at ${this.props.config.practiceName}`;
      }
    } else {
      providerName = `at ${this.props.config.practiceName}`;
    }

    // all patients
    questions.forEach((ele, i) => {
      if (ele.type === 1 && ele.condition === 1) {
        questionsPlaces.push(ele);
        topQuestions.push(<TextQuestion dob={this.state.dob} onChange={this.update(`question-${ele.id}`)} value={this.state[`${ele}`]} refs={`textInput${ele.id}`} disabled={this.state.show} key={i} data={ele} />);
      } else if (ele.type === 2 && ele.condition === 1) {
        questionsPlaces.push(ele);
        topQuestions.push(<RadioQuestion dob={this.state.dob} state={this.state} onChange={this.selectionChanged(`question-${ele.id}`)} key={i} data={ele} />);
      } else if (ele.type === 3 && ele.condition === 1) {
        questionsPlaces.push(ele);
        topQuestions.push(<DropdownQuestion dob={this.state.dob} onChange={this.dropdownChanged(`question-${ele.id}`)} key={i} data={ele} />);
      }
    });

    var answeredRequired = true;

    questionsPlaces.forEach(el => {
      if ((el.required === 1 && this.state[`question-${el.id}`] === undefined) || (el.required === 1 && this.state[`question-${el.id}`] === "")) {

        if (el.agerestrict !== "") {
          const restrictDob = moment(this.state.dob).format('YYYY-MM-DD');
          if (restrictDob !== "Invalid date") {
            var a = moment();
            var diff = a.diff(restrictDob, 'years');

            if (diff <= el.agerestrict) {
              if (this.state[`question-${el.id}`] === "" || this.state[`question-${el.id}`] === undefined) {
                answeredRequired = false;
              }
            }
          }
        } else {
          answeredRequired = false;
        }
      }
    });

    const isEnabled = fname.length > 0 && lname.length > 0 && email.length > 0 && dob.length > 0 && phone.length > 0 && insurance.length > 1 && ins && answeredRequired;

    return (
      <div className="scheduleAppointmentContainer">
        <div ref={el => { this.el = el; }} className="blurry row justify-content-center">
          <div className="col col-11 schedule_appointment_header">
            <FlexIcon class="scheduleApptImage" icon={appointment.icon} />

            <div className="appointmentHead">
              {appointment.name}
            </div>

            <div className="appointmentHeadLength">
              {appointment.length} minutes
            </div>

            <div className="appointmentTimes">
              {moment(selectedAppointment.date).format("dddd, MMMM D")} at {moment(selectedAppointment.starttime, "hh:mm:ss").format("h:mm a").replace(/(a|p)(m)/,'$1.$2.')}
            </div>
            <div className="schedApptProvider">
              {providerName}
            </div>
          </div>


          {!this.state.loading ? (
              <>
                <div id="firstInput" ref={el => { this.el = el; }} className="field col-sm-10 col-md-4">
                  <input onKeyPress={(event) => this.handleKeyPress('fname', event)} ref='fname' onFocus={this.scrollToFirst} disabled={this.state.show} onChange={this.update("fname")} type="name" className="form-control" id="fname" placeholder="First Name"/>
                </div>
                <div id="lastInput" ref={el => { this.el = el; }} className="field col-sm-10 col-md-4">
                  <input onKeyPress={(event) => this.handleKeyPress('lname', event)} ref='lname' onFocus={this.scrollToLast} disabled={this.state.show} onChange={this.update("lname")} type="name" className="form-control" id="lname" placeholder="Last Name"/>
                </div>
                <div id="emailInput" ref={el => { this.el = el; }}  className="field col-sm-10 col-md-8">
                  <input onKeyPress={(event) => this.handleKeyPress('email', event)} ref='email' onFocus={this.scrollToEmail} disabled={this.state.show} onChange={this.handleEmail} type="email" className="form-control" id="email" placeholder="Email"/>
                </div>

                <div className="field col-sm-10 col-md-8">
                  <label className="col col-12 label dobLabel" htmlFor="birthdate">Date of Birth</label>
                  <div className="dobDiv">
                    <select onKeyPress={(event) => this.handleKeyPress('month', event)} ref='month' name='date-of-birth-month' id="date-of-birth-month" disabled={this.state.show} onChange={this.handleDob} className="form-control monthSelect">
                      <option value='1'>January</option>
                      <option value='2'>February</option>
                      <option value='3'>March</option>
                      <option value='4'>April</option>
                      <option value='5'>May</option>
                      <option value='6'>June</option>
                      <option value='7'>July</option>
                      <option value='8'>August</option>
                      <option value='9'>September</option>
                      <option value='10'>October</option>
                      <option value='11'>November</option>
                      <option value='12'>December</option>
                    </select>

                    <input onKeyPress={(event) => this.handleKeyPress('day', event)} ref='day' type="number" name="date-of-birth-day" id="date-of-birth-day" disabled={this.state.show} onChange={this.handleDob} className='form-control dayInput' min="1" max="31" placeholder="DD"/>
                    <input onKeyPress={(event) => this.handleKeyPress('year', event)} ref='year' type="number" name="date-of-birth-year" id="date-of-birth-year" disabled={this.state.show} onChange={this.handleDob} className='form-control yearInput' min="1900" max="2020" placeholder="YYYY"/>
                  </div>
                </div>

                <div id="phoneInput" ref={el => { this.el = el; }}  className="field col-sm-10 col-md-8">
                  <input onKeyPress={(event) => this.handleKeyPress('phone', event)} ref='phone' onFocus={this.scrollToPhone} onKeyDown={this.onKeyPress} disabled={this.state.show} value={this.state.phone} onChange={this.handlePhone} className="form-control" placeholder="Mobile Number" name='mobile' id='mobile' autoComplete='mobile tel'/>
                </div>

                {sendCodeAlert}

                <hr className="field col-8"
                  style={{
                    color: "#d3d3d3",
                    backgroundColor: "#d3d3d3",
                  }}
                />

                {topQuestions}

                <div className="col col-11 insuranceContainer">
                  {insuranceDiv}

                  {insuranceSelection}

                  {insuranceFillField}

                  {subscriberFillField}

                  {questionsDiv}

                  {insuranceMessage}
                </div>

                <ContinueButton id="continueButton" disabled={!isEnabled || this.state.show} className="col col-11 scheduleApptButton btn btn-primary" onClick={this.handleSubmit}>Schedule Appointment</ContinueButton>
              </>
          ) : (
            <div className="col col-12 createLoader">
              <Spacer height="10px" />
              <PulseLoader color={"#707070"}  size={10} />
            </div>
          )}
          </div>
        <Modal onClose={this.showModal} show={this.state.show} phone={this.state.phone}>Message in Modal</Modal>
      </div>
    )
  }
}

export default ScheduleAppointment;
